.root {
  padding: 1rem;
  margin: 0 auto;
  max-width: 480px;
}


.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.header > img {
  width: 4rem;
}

.header .title {
  margin-top: 1rem;
  font-size: 1rem;
}

.item {
  margin-bottom: 1rem;
}

.row {
  display: flex;
}

.row > :global(.ant-form-item) {
  flex: 1;
  margin: 0;
}

.row > :global(.ant-btn) {
  margin-left: .5rem;
}

.divider {
  margin: 0 -1rem 1.25rem -1rem;
  height: 1px;
  background-color: rgba(0, 0, 0, .1);
}