.root {
  padding: 1rem;
  margin: 0 auto;
  max-width: 480px;
}

.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.header > img {
  width: 4rem;
}

.header .title {
  margin-top: 1rem;
  font-size: 1rem;
}

.item {
  margin-bottom: 1rem;
}

.links {
  display: flex;
  justify-content: space-between;
}
