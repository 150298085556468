.root {
  padding: 1rem;
  width: 100%;
  overflow: scroll;
}

.root h2 {
  position: sticky;
  left: 0;
  margin: 2rem 0 1rem 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.root p {
  position: sticky;
  left: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.root table {
  margin-bottom: 1rem;
  width: 640px
}

.root table tr > td, .root table tr > th {
  padding: .25rem 1rem;
  border: 1px #cccccc solid;
  text-align: center;
}

.root table tr > td:first-child {
  font-weight: 500;
  text-align: right;
}

.root ul {
  padding-left: 1.25rem;
  position: sticky;
  left: 0;
  font-size: 1rem;
}

.root ul > li strong {
  margin: 0 .5rem;
  color: red;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}