.root {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background-color: #333333;
  z-index: 1;
}

.left, .right {
  width: 3rem;
}

.center {
  font-size: 1rem;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.primary {
  font-size: 1.25rem;
}

.secondary {
  font-size: .875rem;
  opacity: .5;
}

.button {
  width: 3rem;
  height: 3rem;
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;
  transition: all .3s;
}

.button:hover {
  background-color: rgba(255, 255, 255, .05);
}

.button:active {
  transform: scale(.875);
}

.action {
  padding: 1rem;
}

.action > .divider {
  margin: 1rem -1rem .5rem -1rem;
  height: 1px;
  background-color: rgba(0, 0, 0, .1);
}