.root {
  padding: 0 1rem 4rem 1rem;  
}

.wrapper {
  padding: 2rem 0;
  line-height: 1;
  background-color: rgba(0, 0, 0, .025);
  border-radius: 16px;
}

.title {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.qrcode {
  margin-bottom: 1rem;
  text-align: center;
  font-size: .75rem;
  color: #999999;
}

.qrcode > img {
  margin-bottom: .25rem;
  width: 6rem;
}

.hotline {
  margin-bottom: 1rem;
  text-align: center;
  color: #999999;
  font-size: .75rem;
  line-height: 1.25;
}

.hotline a {
  font-family: monospace;
  font-size: 1.5rem;
  color: #42b2b5;
}

.copyright {
  text-align: center;
  color: #cccccc;
  font-size: .75rem;
}

.copyright a {
  margin: 0 .25rem;
  color: #999999;
}