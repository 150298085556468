.list {
  margin-bottom: 1rem;
}


.list > .item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.list > .item > .head {
  padding-left: 1rem;
  padding-right: .5rem;
  /* background-color: red; */
}

.list > .item > .body {
  flex: 1;
  display: flex;
  align-items: center;
  padding: .5rem 0 .5rem .5rem;
  border-bottom: 1px rgba(0, 0, 0, .05) solid;
  transition: all .3s;
}

.list > .item > .body:hover {
  background-color: rgba(0, 0, 0, .025);
}

.list > .item > .body:active {
  background-color: rgba(0, 0, 0, .05);
}

.list > .item > .body > .left {
  flex: 1;
}

.list > .item > .body > .right {
  display: flex;
  padding-right: 1rem;
  
}
/* .iconwarpper{
  margin: 1rem;
  line-height: 1;
  background-color: rgba(112, 49, 49, 0.025);
  border-radius: 16px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iconwarpper > .icon{
  font-size: 5rem;
  margin-bottom: 3rem;
} */
.empty {
  padding: 2rem;
}
.primary {
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: .25rem;
}

.secondary {
  color: rgba(0, 0, 0, .25);
}

.indicator {
  margin-left: .5rem;
  color: rgba(0, 0, 0, .25);
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}