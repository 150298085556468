.root {

}

.root :global(.ant-form-item) {
  padding: 0 1rem;
}

.title {
  position: sticky;
  top: 4rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  height: 2rem;
  line-height: 2rem;
  background-color: #333333;
  color: #ffffff;
  z-index: 1;
}

.description {
  background-color: rgba(0, 0, 0, .05);
  padding: 1rem;
  margin-top: -.5rem;
  margin-bottom: 1.5rem;
}

.description ul {
  padding-left: 1.5rem;
  color: #999999;
}

.instruction .primary {
  background-color: #333333;
  color: #ffffff;
  padding: .25rem .5rem;
}

.instruction ul {
  padding-left: 1.75rem;
}